import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(public translateService: TranslateService) {
    translateService.addLangs(['en', 'es']);
    translateService.setDefaultLang('es');
    const lang =
      localStorage.getItem('language') ||
      translateService.getBrowserCultureLang();

    switch (lang) {
      case 'en':
        translateService.use('en');
        break;
      default:
        translateService.use('es');
        break;
    }
  }
}
