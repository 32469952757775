import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const routes: string[] = ['receipts', 'companies', 'offers', 'products'];
    const hasToken: boolean = routes.some(route => req.url.includes(route));
    const token: string = sessionStorage.getItem('token') ?? '';
    if (hasToken) {
      return next
        .handle(
          req.clone({
            headers: req.headers.append('Authorization', token)
          })
        )
        .pipe(
          catchError(error => {
            console.error('ERROR', error);
            if (error.status === 403) {
              sessionStorage.clear();
              this.router.navigate(['login'], {
                state: { error: error.status }
              });
            }
            return throwError(error);
          })
        );
    }
    return next.handle(req).pipe(
      catchError(error => {
        console.error('ERROR', error);
        return throwError(error.message);
      })
    );
  }
}
